import { Form, FormItem } from "@optimed/react";
import { useEffect, useState } from "react";
import { ReceptyKontekstWywolaniaP1 } from "../../../api/models";

interface P1ContextAutoInfererProps {
  model: ReceptyKontekstWywolaniaP1;
  onChange: (value: ReceptyKontekstWywolaniaP1) => any;
  xml: string;
}

const emptyContext: ReceptyKontekstWywolaniaP1 = {};

export function P1ContextAutoInferer(props: P1ContextAutoInfererProps) {
  const { model, onChange, xml } = props;
  const [inferError, setInferError] = useState(false);

  useEffect(() => {
    if (!xml) {
      setInferError(false);
      onChange(emptyContext);
      return;
    }

    const p1Context = inferP1Context(xml);
    setInferError(!p1Context);
    onChange(p1Context || emptyContext);
  }, [xml, onChange]);

  return (
    <div>
      <Form layout="inline">
        <FormItem label="Nr w P1">{model.numerKontaUslugodawcy}</FormItem>
        <FormItem label="Res I">{model.kodResortowyI}</FormItem>
        <FormItem label="Res V">{model.kodResortowyV}</FormItem>
        <FormItem label="Res VII">{model.kodResortowyVII}</FormItem>
        <FormItem label="NPWZ">{model.npwz}</FormItem>
      </Form>
      <div>{inferError && <div className="text-danger">Błąd - nie udało się wykryć danych kontekstu P1</div>}</div>
    </div>
  );
}

function inferP1Context(xml: string): ReceptyKontekstWywolaniaP1 | undefined {
  const nrKontaMatch = /2\.16\.840\.1\.113883\.3\.4424\.2\.7\.(\d+)\.2\.1/.exec(xml);
  const npwzMatch = /root="2\.16\.840\.1\.113883\.3\.4424\.1\.6\.2" extension="([^"]+)"/.exec(xml);
  const res1Match = /root="2\.16\.840\.1\.113883\.3\.4424\.2\.3\.1" extension="([^"]+)"/.exec(xml);

  if (!nrKontaMatch || !npwzMatch || !res1Match) return undefined;

  const result: ReceptyKontekstWywolaniaP1 = {
    numerKontaUslugodawcy: nrKontaMatch[1],
    kodResortowyI: res1Match[1],
    kodResortowyV: "01",
    kodResortowyVII: "001",
    npwz: npwzMatch[1],
  };

  return result;
}
