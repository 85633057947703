import { DialogRenderer, ToastRenderer } from "@optimed/react";
import React from "react";
import { HashRouter } from "react-router-dom";
import { MainRouterSwitch } from "../routing/MainRouterSwitch";
import { TopMenu } from "./TopMenu";

export function Main() {
  return (
    <HashRouter>
      <DialogRenderer />
      <ToastRenderer />
      <TopMenu />
      <MainRouterSwitch />
    </HashRouter>
  );
}
