import { Alert, PendingOperationState } from "@optimed/react";
import React from "react";

export function loadingRegionDefaultRenderer(children: React.ReactNode, op: PendingOperationState): React.ReactNode {
  const message = extractMessageFromOp(op);

  return <Alert type="danger">{message}</Alert>;
}

function extractMessageFromOp(op: PendingOperationState): React.ReactNode {
  let result: React.ReactNode = op.errorMessage || "Wystąpił błąd operacji";

  if (op.rawError?.response?.data?.type === "SystemP1ErrorResponse") {
    const p1Err = op.rawError.response.data;
    if (p1Err.errors && p1Err.errors.length) {
      return (
        <div>
          Błędy platformy P1:
          {p1Err.errors.map((x: any, i: number) => (
            <div key={i}>{x.message}</div>
          ))}
        </div>
      );
    }
  }

  return result;
}
