import {
  Button,
  clipboardUtils,
  Grid,
  GridItem,
  LoadingRegion,
  TextInput,
  useDataLoaderWithPendingOperation,
  useLocalStorage,
} from "@optimed/react";
import { zdarzeniaMedyczneTestService } from "api";
import { KontekstZapytaniaZm, TypSwiadczeniodawcy, ZmRolaUzytkownika, ZmStringZmRequest } from "api/models";
import { useState } from "react";
import { ZmContextForm } from "../ZmContextForm";

export function RawFhirGetPage() {
  const [zmContext, setZmContext] = useState<KontekstZapytaniaZm>({
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    identyfikatorUzytkownika: "1091231",
    numerKontaUslugodawcy: "51",
    kodResortowyI: "000000814987",
    rolaUzytkownika: ZmRolaUzytkownika.Lekarz,
  });
  const [url, setUrl] = useLocalStorage("rawFhirGetUrl", "");
  const [submittedRequest, setSubmittedRequest] = useState<ZmStringZmRequest>();
  const [result, op] = useDataLoaderWithPendingOperation(
    () =>
      submittedRequest ? zdarzeniaMedyczneTestService.zmTestRawFhirGet(submittedRequest) : Promise.resolve(undefined),
    [submittedRequest]
  );

  const send = () => {
    if (op.syncPending) return;
    if (!url) return;

    setSubmittedRequest({ kontekst: zmContext, payload: url });
  };

  return (
    <Grid gap>
      <GridItem colTemplate="300px" scrollable>
        <h5>Kontekst komunikacji z P1</h5>
        <ZmContextForm value={zmContext} onChange={setZmContext} />
        {result && (
          <>
            <h5>Odpowiedź FHIR</h5>
            <div>Kod odpowiedzi: {result.statusCode}</div>
            {result.response && (
              <Button onClick={() => clipboardUtils.copyTextToClipboard(result.response || "")}>
                Kopiuj odpowiedź do schowka
              </Button>
            )}
          </>
        )}
      </GridItem>
      <GridItem colTemplate="1fr">
        <Grid gap>
          <GridItem rowTemplate="auto">
            <Grid gap>
              <GridItem colTemplate="1fr">
                <TextInput
                  value={url}
                  onChange={setUrl}
                  placeholder="URL zapytania GET FHIR np. Encounter/1, Patient/2"
                />
              </GridItem>
              <GridItem colTemplate="auto">
                <Button onClick={send} disabled={op.state.pending || !url}>
                  Wyślij zapytanie
                </Button>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem rowTemplate="1fr" scrollable>
            {op.state.error ? (
              <div>
                <h3>Błąd wykonania operacji</h3>
                {op.state.errorMessage}
              </div>
            ) : (
              <LoadingRegion promise={op.promise}>
                <div style={{ whiteSpace: "pre-wrap", height: "100%" }}>{result?.response}</div>
              </LoadingRegion>
            )}
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
}
