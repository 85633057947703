import { BaseContainer } from "@optimed/react";
import React from "react";
import { MigrateEReceptaService } from "./MigrateEReceptaService";
import { SyncMissingPrescriptionsKeys } from "./SyncMissingPrescriptionsKeys";

interface ServiceOperationsPageProps {}

export function ServiceOperationsPage(props: ServiceOperationsPageProps) {
  return (
    <BaseContainer padding>
      <MigrateEReceptaService />
      <SyncMissingPrescriptionsKeys />
    </BaseContainer>
  );
}
