import {
  DateField,
  Form,
  FormItem,
  NumberField,
  SelectField,
  SubmitButton,
  TextField,
  TimeField,
  useLocalStorage,
  ValidationResult,
} from "@optimed/react";
import { useEffect } from "react";
import { ZmListaZdarzenMedycznychCriteria } from "../../../api/models";

interface EncounterQueryP1CriteriaProps {
  criteria: ZmListaZdarzenMedycznychCriteria;
  validation?: ValidationResult;
  onChange: (criteria: ZmListaZdarzenMedycznychCriteria) => any;
  onSubmit: (criteria: ZmListaZdarzenMedycznychCriteria) => any;
}

interface SortOrderItem {
  value: boolean | null;
  label: string;
}

const sortOrders: SortOrderItem[] = [
  { value: null, label: "Brak sortowania" },
  { value: true, label: "Od najnowszych" },
  { value: false, label: "Od najstarszych" },
];

export function EncounterQueryP1Criteria(props: EncounterQueryP1CriteriaProps) {
  const { criteria, onChange, onSubmit, validation } = props;

  const [pacjentPesel, setPacjentPesel] = useLocalStorage("pacjentPesel", "");

  useEffect(() => {
    onChange({ ...criteria, identyfikatorPacjenta: pacjentPesel });
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPacjentPesel(criteria.identyfikatorPacjenta || "");
    // eslint-disable-next-line
  }, [criteria]);

  return (
    <Form
      layout="vertical"
      condensed
      model={criteria}
      onChange={onChange}
      onSubmit={onSubmit}
      validationResult={validation}
    >
      <FormItem label="Data od">
        <DateField name="dataOd" modelFormat="ISO8601" />
        <TimeField name="dataOd" modelFormat="ISO8601" />
      </FormItem>

      <FormItem label="Data do">
        <DateField name="dataDo" modelFormat="ISO8601" />
        <TimeField name="dataDo" modelFormat="ISO8601" />
      </FormItem>

      <FormItem label="Pesel">
        <TextField name="identyfikatorPacjenta" />
      </FormItem>

      <FormItem label="Sortowanie">
        <SelectField name="sortujOdNajnowszych" items={sortOrders} itemKey="value" itemKeyAsModel hideClearIcon />
      </FormItem>

      <FormItem label="Limit liczby zdarzeń">
        <NumberField name="limitLiczbyZdarzen" />
      </FormItem>

      <SubmitButton>Szukaj</SubmitButton>
    </Form>
  );
}
