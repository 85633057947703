import { useDataLoader } from "@optimed/react";
import React from "react";
import { environmentService } from "../../api";

export function ServerInfo() {
  const [serverInfo] = useDataLoader(() => environmentService.appInfo());

  if (!serverInfo) return null;

  return (
    <>
      <span>Server: {serverInfo.assemblyVersion || "?"}</span>
      <span>
        Env: {serverInfo.environmentName || "?"}
        {serverInfo.p1DemoMode && ", P1 demo mode"}
      </span>
    </>
  );
}
