const prescriptions = {
  find: "/prescriptions/find",
  manualXml: "/prescriptions/manual_xml",
  findInP1: "/prescriptions/find_p1",
};

const referrals = {
  read: "/referrals/read",
};

const encounters = {
  queryP1: "/encounters/query_p1",
  rawFhirGet: "encounters/raw_fhir_get",
};

const patientConsents = {
  read: "/patient_consents/read",
};

export const routes = {
  prescriptions,
  encounters,
  referrals,
  patientConsents,
  serviceOperations: "/service_operations",
};
