import {
  Button,
  Checkbox,
  DialogContent,
  DialogProps,
  Grid,
  GridItem,
  makeDialogOpener,
  useDialogSize,
} from "@optimed/react";
import React, { useState } from "react";

interface DeleteConfirmDialogProps extends DialogProps<void> {
  dialogHeader?: string;
  question: string;
  additionalAgreeCheckbox?: boolean;
}

export function DeleteConfirmDialog(props: DeleteConfirmDialogProps) {
  const { dialog, dialogHeader, question, additionalAgreeCheckbox } = props;
  useDialogSize("500px");
  const [agreeTicked, setAgreeTicked] = useState(false);

  const canAccept = !additionalAgreeCheckbox || agreeTicked;

  const onAccept = () => {
    if (!canAccept) return;
    dialog.close();
  };

  return (
    <DialogContent header={dialogHeader || "Potwierdzenie usunięcia"}>
      <Grid gap>
        <GridItem rowTemplate="1fr">{question}</GridItem>
        {additionalAgreeCheckbox && (
          <GridItem rowTemplate="auto">
            <Checkbox value={agreeTicked} onChange={setAgreeTicked}>
              <strong>Potwierdzam chęć wykonania operacji usunięcia</strong>
            </Checkbox>
          </GridItem>
        )}
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={dialog.cancel}>Anuluj</Button>
          <Button solid color="danger" onClick={onAccept} disabled={!canAccept}>
            Usuń
          </Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

export const deleteConfirmDialog = makeDialogOpener(DeleteConfirmDialog);
