// File generated automatically, do not modify

import axios, { AxiosResponse } from 'axios';
import { ReceptyRecepta, ReceptyPakietRecept, ReceptyDaneDoOdczytuPakietuReceptZp1ZapytanieZKontekstemP1, ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1, ReceptyWeryfikacjaPakietuReceptResult, ReceptyZapisPakietuReceptResult, ReceptyDaneDoAnulowaniaReceptyZapytanieZKontekstemP1, ReceptyAnulowanieReceptyResult, ReceptyGenerowanieDokumentowPakietuReceptResult, ReceptyWyslijPakietReceptDoPodpisuPzResult, ReceptyDaneDoWystawieniaPakietuReceptListZapytanieZKontekstemP1, ReceptyWyslijWielePakietowReceptDoPodpisuPzResult, ReceptyWyslijDokumentAnulowaniaReceptyDoPodpisuPzResult, ReceptyOdczytPakietuReceptZp1Result, ReceptyDaneDoOdczytuReceptyZp1ZapytanieZKontekstemP1, ReceptyOdczytReceptyZp1Result, ReceptyDaneWyszukiwaniaReceptWystawiajacegoZapytanieZKontekstemP1, ReceptyWyszukanieReceptWystawiajacegoResult, ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzoneZapytanieZKontekstemP1, ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResult, ReceptyWyszukanieProduktowRplQuery, ReceptyWyszukiwanieProduktowRplResult } from './models';

const paths = {
  daneWystawionejRecepty: 'api/recepty/DaneWystawionejRecepty',
  daneWystawionegoPakietu: 'api/recepty/DaneWystawionegoPakietu',
  wydrukPakietuRecept: 'api/recepty/WydrukPakietuRecept',
  wydrukPakietuReceptPdf: 'api/recepty/WydrukPakietuReceptPdf',
  wydrukPakietuReceptZP1Pdf: 'api/recepty/WydrukPakietuReceptZP1Pdf',
  wydrukRecepty: 'api/recepty/WydrukRecepty',
  weryfikacjaPakietuRecept: 'api/recepty/WeryfikacjaPakietuRecept',
  zapisPakietuRecept: 'api/recepty/ZapisPakietuRecept',
  zapisPakietuReceptPodpisanychPZ: 'api/recepty/ZapisPakietuReceptPodpisanychPZ',
  anulowanieRecepty: 'api/recepty/AnulowanieRecepty',
  generowanieDokumentowPakietuRecept: 'api/recepty/GenerowanieDokumentowPakietuRecept',
  zapisAnulowaniaReceptyPodpisanegoPZ: 'api/recepty/ZapisAnulowaniaReceptyPodpisanegoPZ',
  wyslijPakietReceptDoPodpisuPZ: 'api/recepty/WyslijPakietReceptDoPodpisuPZ',
  wyslijWielePakietowReceptDoPodpisuPZ: 'api/recepty/WyslijWielePakietowReceptDoPodpisuPZ',
  wyslijDokumentAnulowaniaReceptyDoPodpisuPZ: 'api/recepty/WyslijDokumentAnulowaniaReceptyDoPodpisuPZ',
  odczytPakietuReceptZP1: 'api/recepty/OdczytPakietuReceptZP1',
  odczytReceptyZP1: 'api/recepty/OdczytReceptyZP1',
  odczytDanychDostepowychPakietuRecept: 'api/recepty/OdczytDanychDostepowychPakietuRecept',
  wyszukanieReceptWystawiajacego: 'api/recepty/WyszukanieReceptWystawiajacego',
  rozszerzoneWyszukanieReceptUslugobiorcy: 'api/recepty/RozszerzoneWyszukanieReceptUslugobiorcy',
  wyszukanieSurowcowFarmaceutycznych: 'api/recepty/WyszukanieSurowcowFarmaceutycznych',
  wyszukanieProduktowLeczniczych: 'api/recepty/WyszukanieProduktowLeczniczych',
};

interface DaneWystawionejReceptyParams {
  idRecepty?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
  zwrocXml?: boolean;
}

function daneWystawionejRecepty(params: DaneWystawionejReceptyParams): Promise<ReceptyRecepta> {
  return axios.request<ReceptyRecepta>({
    url: paths.daneWystawionejRecepty,
    method: 'get',
    params,
  }).then(res => res.data);
}

interface DaneWystawionegoPakietuParams {
  idPakietu?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
  zwrocXml?: boolean;
}

function daneWystawionegoPakietu(params: DaneWystawionegoPakietuParams): Promise<ReceptyPakietRecept> {
  return axios.request<ReceptyPakietRecept>({
    url: paths.daneWystawionegoPakietu,
    method: 'get',
    params,
  }).then(res => res.data);
}

interface WydrukPakietuReceptParams {
  idPakietu?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
}

function wydrukPakietuRecept(params: WydrukPakietuReceptParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukPakietuRecept,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

interface WydrukPakietuReceptPdfParams {
  idPakietu?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
}

function wydrukPakietuReceptPdf(params: WydrukPakietuReceptPdfParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukPakietuReceptPdf,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

function wydrukPakietuReceptZP1Pdf(data: ReceptyDaneDoOdczytuPakietuReceptZp1ZapytanieZKontekstemP1): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukPakietuReceptZP1Pdf,
    method: 'post',
    responseType: 'blob',
    data,
  }).then(convertBlobResponseToFile);
}

interface WydrukReceptyParams {
  idRecepty?: number;
  idZewnetrzne?: number;
  kodSystemu?: string;
}

function wydrukRecepty(params: WydrukReceptyParams): Promise<File> {
  return axios.request<Blob>({
    url: paths.wydrukRecepty,
    method: 'get',
    params,
    responseType: 'blob',
  }).then(convertBlobResponseToFile);
}

interface WeryfikacjaPakietuReceptParams {
  zwrocXml?: boolean;
  operationSecondsTimeout?: number;
}

function weryfikacjaPakietuRecept(args: { params: WeryfikacjaPakietuReceptParams; data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1; }): Promise<ReceptyWeryfikacjaPakietuReceptResult> {
  const { params, data } = args;
  return axios.request<ReceptyWeryfikacjaPakietuReceptResult>({
    url: paths.weryfikacjaPakietuRecept,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface ZapisPakietuReceptParams {
  wykonajWeryfikacje?: boolean;
  zwrocXml?: boolean;
}

function zapisPakietuRecept(args: { params: ZapisPakietuReceptParams; data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1; }): Promise<ReceptyZapisPakietuReceptResult> {
  const { params, data } = args;
  return axios.request<ReceptyZapisPakietuReceptResult>({
    url: paths.zapisPakietuRecept,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface ZapisPakietuReceptPodpisanychPZParams {
  ePuapRequestId?: string;
  ePuapRequestWideStartIdx?: number;
  wykonajWeryfikacje?: boolean;
  zwrocXml?: boolean;
}

function zapisPakietuReceptPodpisanychPZ(args: { params: ZapisPakietuReceptPodpisanychPZParams; data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1; }): Promise<ReceptyZapisPakietuReceptResult> {
  const { params, data } = args;
  return axios.request<ReceptyZapisPakietuReceptResult>({
    url: paths.zapisPakietuReceptPodpisanychPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface AnulowanieReceptyParams {
  zwrocXml?: boolean;
}

function anulowanieRecepty(args: { params: AnulowanieReceptyParams; data: ReceptyDaneDoAnulowaniaReceptyZapytanieZKontekstemP1; }): Promise<ReceptyAnulowanieReceptyResult> {
  const { params, data } = args;
  return axios.request<ReceptyAnulowanieReceptyResult>({
    url: paths.anulowanieRecepty,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function generowanieDokumentowPakietuRecept(data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1): Promise<ReceptyGenerowanieDokumentowPakietuReceptResult> {
  return axios.request<ReceptyGenerowanieDokumentowPakietuReceptResult>({
    url: paths.generowanieDokumentowPakietuRecept,
    method: 'post',
    data,
  }).then(res => res.data);
}

interface ZapisAnulowaniaReceptyPodpisanegoPZParams {
  ePuapToken?: string;
  zwrocXml?: boolean;
}

function zapisAnulowaniaReceptyPodpisanegoPZ(args: { params: ZapisAnulowaniaReceptyPodpisanegoPZParams; data: ReceptyDaneDoAnulowaniaReceptyZapytanieZKontekstemP1; }): Promise<ReceptyAnulowanieReceptyResult> {
  const { params, data } = args;
  return axios.request<ReceptyAnulowanieReceptyResult>({
    url: paths.zapisAnulowaniaReceptyPodpisanegoPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WyslijPakietReceptDoPodpisuPZParams {
  zwrocXml?: boolean;
  successUrl?: string;
  failurUrl?: string;
  operationSecondsTimeout?: number;
}

function wyslijPakietReceptDoPodpisuPZ(args: { params: WyslijPakietReceptDoPodpisuPZParams; data: ReceptyDaneDoWystawieniaPakietuReceptZapytanieZKontekstemP1; }): Promise<ReceptyWyslijPakietReceptDoPodpisuPzResult> {
  const { params, data } = args;
  return axios.request<ReceptyWyslijPakietReceptDoPodpisuPzResult>({
    url: paths.wyslijPakietReceptDoPodpisuPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WyslijWielePakietowReceptDoPodpisuPZParams {
  successUrl?: string;
  failurUrl?: string;
}

function wyslijWielePakietowReceptDoPodpisuPZ(args: { params: WyslijWielePakietowReceptDoPodpisuPZParams; data: ReceptyDaneDoWystawieniaPakietuReceptListZapytanieZKontekstemP1; }): Promise<ReceptyWyslijWielePakietowReceptDoPodpisuPzResult> {
  const { params, data } = args;
  return axios.request<ReceptyWyslijWielePakietowReceptDoPodpisuPzResult>({
    url: paths.wyslijWielePakietowReceptDoPodpisuPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface WyslijDokumentAnulowaniaReceptyDoPodpisuPZParams {
  successUrl?: string;
  failurUrl?: string;
}

function wyslijDokumentAnulowaniaReceptyDoPodpisuPZ(args: { params: WyslijDokumentAnulowaniaReceptyDoPodpisuPZParams; data: ReceptyDaneDoAnulowaniaReceptyZapytanieZKontekstemP1; }): Promise<ReceptyWyslijDokumentAnulowaniaReceptyDoPodpisuPzResult> {
  const { params, data } = args;
  return axios.request<ReceptyWyslijDokumentAnulowaniaReceptyDoPodpisuPzResult>({
    url: paths.wyslijDokumentAnulowaniaReceptyDoPodpisuPZ,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface OdczytPakietuReceptZP1Params {
  zwrocXml?: boolean;
}

function odczytPakietuReceptZP1(args: { params: OdczytPakietuReceptZP1Params; data: ReceptyDaneDoOdczytuPakietuReceptZp1ZapytanieZKontekstemP1; }): Promise<ReceptyOdczytPakietuReceptZp1Result> {
  const { params, data } = args;
  return axios.request<ReceptyOdczytPakietuReceptZp1Result>({
    url: paths.odczytPakietuReceptZP1,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

interface OdczytReceptyZP1Params {
  zwrocXml?: boolean;
}

function odczytReceptyZP1(args: { params: OdczytReceptyZP1Params; data: ReceptyDaneDoOdczytuReceptyZp1ZapytanieZKontekstemP1; }): Promise<ReceptyOdczytReceptyZp1Result> {
  const { params, data } = args;
  return axios.request<ReceptyOdczytReceptyZp1Result>({
    url: paths.odczytReceptyZP1,
    method: 'post',
    params,
    data,
  }).then(res => res.data);
}

function odczytDanychDostepowychPakietuRecept(): Promise<string> {
  return axios.request<string>({
    url: paths.odczytDanychDostepowychPakietuRecept,
    method: 'get',
  }).then(res => res.data);
}

function wyszukanieReceptWystawiajacego(data: ReceptyDaneWyszukiwaniaReceptWystawiajacegoZapytanieZKontekstemP1): Promise<ReceptyWyszukanieReceptWystawiajacegoResult> {
  return axios.request<ReceptyWyszukanieReceptWystawiajacegoResult>({
    url: paths.wyszukanieReceptWystawiajacego,
    method: 'post',
    data,
  }).then(res => res.data);
}

function rozszerzoneWyszukanieReceptUslugobiorcy(data: ReceptyDaneWyszukiwaniaReceptUslugobiorcyRozszerzoneZapytanieZKontekstemP1): Promise<ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResult> {
  return axios.request<ReceptyWyszukiwanieReceptUslugobiorcyRozszerzoneResult>({
    url: paths.rozszerzoneWyszukanieReceptUslugobiorcy,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wyszukanieSurowcowFarmaceutycznych(data: ReceptyWyszukanieProduktowRplQuery): Promise<ReceptyWyszukiwanieProduktowRplResult> {
  return axios.request<ReceptyWyszukiwanieProduktowRplResult>({
    url: paths.wyszukanieSurowcowFarmaceutycznych,
    method: 'post',
    data,
  }).then(res => res.data);
}

function wyszukanieProduktowLeczniczych(data: ReceptyWyszukanieProduktowRplQuery): Promise<ReceptyWyszukiwanieProduktowRplResult> {
  return axios.request<ReceptyWyszukiwanieProduktowRplResult>({
    url: paths.wyszukanieProduktowLeczniczych,
    method: 'post',
    data,
  }).then(res => res.data);
}

function convertBlobResponseToFile(res: AxiosResponse<Blob>): File {
  const contentDispositionHeader = res.headers['content-disposition'] || '';
  const filenameMatch = /filename="?([^;,"]+)/.exec(contentDispositionHeader);
  const filename = filenameMatch ? filenameMatch[1] : '';
  return new File([res.data], filename);
}

export const receptyService = {
  paths,
  daneWystawionejRecepty,
  daneWystawionegoPakietu,
  wydrukPakietuRecept,
  wydrukPakietuReceptPdf,
  wydrukPakietuReceptZP1Pdf,
  wydrukRecepty,
  weryfikacjaPakietuRecept,
  zapisPakietuRecept,
  zapisPakietuReceptPodpisanychPZ,
  anulowanieRecepty,
  generowanieDokumentowPakietuRecept,
  zapisAnulowaniaReceptyPodpisanegoPZ,
  wyslijPakietReceptDoPodpisuPZ,
  wyslijWielePakietowReceptDoPodpisuPZ,
  wyslijDokumentAnulowaniaReceptyDoPodpisuPZ,
  odczytPakietuReceptZP1,
  odczytReceptyZP1,
  odczytDanychDostepowychPakietuRecept,
  wyszukanieReceptWystawiajacego,
  rozszerzoneWyszukanieReceptUslugobiorcy,
  wyszukanieSurowcowFarmaceutycznych,
  wyszukanieProduktowLeczniczych,
};
