import {
  Checkbox,
  Form,
  FormItem,
  LoadingRegion,
  Panel,
  PanelBody,
  SubmitButton,
  useDataLoaderWithPendingOperation,
  useInterval,
  usePendingOperation,
} from "@optimed/react";
import React, { useState } from "react";
import { eReceptaMigrationService } from "../../api";
import { EReceptaMigrationSourceModule } from "../../api/models";
import { EReceptaMigrationSourceSelector } from "./EReceptaMigrationSourceSelector";
import { EReceptaMigrationStatusView } from "./EReceptaMigrationStatusView";

export function MigrateEReceptaService() {
  const opStart = usePendingOperation();
  const [status, statusOp, refreshStatus] = useDataLoaderWithPendingOperation(() =>
    eReceptaMigrationService.statusMigracji()
  );
  const [sourceUrl, setSourceUrl] = useState<string | EReceptaMigrationSourceModule>("");
  const [migrujRecepty, setMigrujRecepty] = useState(true);
  const [migrujSkierowania, setMigrujSkierowania] = useState(true);
  useInterval(() => {
    if (!statusOp.syncPending) {
      refreshStatus(false);
    }
  }, 1000);

  const canStart = Boolean(sourceUrl && (migrujRecepty || migrujSkierowania) && !opStart.syncPending);

  const start = () => {
    if (!canStart) return;

    opStart.handle(
      eReceptaMigrationService
        .uruchomMigracje({
          eReceptaSourceUrl: typeof sourceUrl === "string" ? sourceUrl : sourceUrl.sourceUrl,
          recepty: migrujRecepty,
          skierowania: migrujSkierowania,
        })
        .then(() => refreshStatus(true))
    );
  };

  return (
    <Panel header="Migracja recept i skierowań z usługi Optimed ERecepta">
      <PanelBody>
        <LoadingRegion promise={statusOp.promise} disableLoadingIf={status}>
          <EReceptaMigrationStatusView status={status} />

          {!status?.isRunning && (
            <Form layout="vertical">
              <FormItem label="Adres URL usługi e-Recepty, z której ma zostać wykonana migracja">
                <EReceptaMigrationSourceSelector value={sourceUrl} onChange={setSourceUrl} />
              </FormItem>
              <FormItem>
                <Checkbox value={migrujRecepty} onChange={setMigrujRecepty}>
                  Migruj recepty
                </Checkbox>
              </FormItem>
              <FormItem>
                <Checkbox value={migrujSkierowania} onChange={setMigrujSkierowania}>
                  Migruj skierowania
                </Checkbox>
              </FormItem>
              <SubmitButton disabled={!canStart} onClick={start}>
                Rozpocznij migrację danych z usługi Optimed ERecepta
              </SubmitButton>
            </Form>
          )}
        </LoadingRegion>
      </PanelBody>
    </Panel>
  );
}
