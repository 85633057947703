import { formatDate, LoadingSpinner, Table, TableHeader, Th } from "@optimed/react";
import React from "react";
import { EReceptaMigrationStatus } from "../../api/models";

interface EReceptaMigrationStatusViewProps {
  status?: EReceptaMigrationStatus;
}

export function EReceptaMigrationStatusView(props: EReceptaMigrationStatusViewProps) {
  const { status } = props;
  if (!status) {
    return null;
  }

  if (!status.isRunning && !status.isFinished) {
    return null;
  }

  return (
    <div style={{ marginBottom: "20px" }}>
      <div>
        {status.isRunning ? (
          <span>
            Operacja w trakcie <LoadingSpinner />
          </span>
        ) : (
          "Operacja zakończona"
        )}
      </div>
      <div>{status.progressMessage}</div>
      <Table>
        <TableHeader>
          <Th></Th>
          <Th>Do migracji</Th>
          <Th>Zmigrowano</Th>
          <Th>Pominięto (zmigrowanych wcześniej)</Th>
          <Th>Błędy migracji</Th>
          <Th>Prędkość</Th>
        </TableHeader>
        <tbody>
          <tr>
            <td>Recepty</td>
            <td>{status.receptyTotalCount}</td>
            <td>{status.receptyMigrated}</td>
            <td>{status.receptySkippedDueToAlreadyMigrated}</td>
            <td>{status.receptyMigrationErrorCount}</td>
            <td>
              {status.receptyStartDate && (
                <div>Start: {formatDate(status.receptyStartDate, "yyyy-MM-dd HH:mm:ss")}</div>
              )}
              {status.receptyEndDate && <div>Koniec: {formatDate(status.receptyEndDate, "yyyy-MM-dd HH:mm:ss")}</div>}
              {!status.receptyEndDate && status.receptyEstimatdEnd && (
                <div>Szacowany koniec: {formatDate(status.receptyEstimatdEnd, "yyyy-MM-dd HH:mm:ss")}</div>
              )}
              {Boolean(status.receptyPerMinute) && <div>Pakiety na minutę: {status.receptyPerMinute}</div>}
            </td>
          </tr>
          <tr>
            <td>Skierowania</td>
            <td>{status.skierowaniaTotalCount}</td>
            <td>{status.skierowaniaMigrated}</td>
            <td>{status.skierowaniaSkippedDueToAlreadyMigrated}</td>
            <td>{status.skierowaniaMigrationErrorCount}</td>
            <td>
              {status.skierowaniaStartDate && (
                <div>Start: {formatDate(status.skierowaniaStartDate, "yyyy-MM-dd HH:mm:ss")}</div>
              )}
              {status.skierowaniaEndDate && (
                <div>Koniec: {formatDate(status.skierowaniaEndDate, "yyyy-MM-dd HH:mm:ss")}</div>
              )}
              {!status.skierowaniaEndDate && status.skierowaniaEstimatdEnd && (
                <div>Szacowany koniec: {formatDate(status.skierowaniaEstimatdEnd, "yyyy-MM-dd HH:mm:ss")}</div>
              )}
              {Boolean(status.skierowaniaPerMinute) && <div>Skierowania na minutę: {status.skierowaniaPerMinute}</div>}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
