export interface AppVersion {
  app: string;
  build: string;
  production: boolean;
}

export const appVersion: AppVersion = {
  app: import.meta.env.VITE_APP_VERSION || "?.?.?",
  build: import.meta.env.VITE_APP_BUILD || "?",
  production: import.meta.env.MODE === "production",
};
