import {
  Button,
  clipboardUtils,
  DialogContent,
  DialogProps,
  Grid,
  GridItem,
  LoadingRegion,
  makeDialogOpener,
  useDataLoader,
  useDialogSize,
} from "@optimed/react";
import { useMemo } from "react";
import { skierowaniaService } from "../../../api";
import { ReceptyKontekstWywolaniaP1 } from "../../../api/models";

interface SkierowanieOdczytDialogProps extends DialogProps<void> {
  p1Ctx: ReceptyKontekstWywolaniaP1;
  poKluczu?: {
    kluczSkierowania: string;
    kodSkierowania: string;
  };
  poId?: {
    root: string;
    extension: string;
  };
}

export function SkierowanieOdczytDialog(props: SkierowanieOdczytDialogProps) {
  useDialogSize("95%", "95%");
  const { dialog } = props;

  const [data, promise] = useDataLoader(() =>
    props.poKluczu
      ? skierowaniaService.odczytDokumentuSkierowaniaDoRealizacji({
          params: {
            zwrocHtml: true,
            zwrocXml: true,
          },
          data: {
            kontekstP1: props.p1Ctx,
            payload: {
              kluczSierowania: props.poKluczu.kluczSkierowania,
              kodSkierowania: props.poKluczu.kodSkierowania,
            },
          },
        })
      : props.poId
      ? skierowaniaService.odczytDokumentuSkierowania({
          params: {
            zwrocHtml: true,
            zwrocXml: true,
          },
          data: {
            kontekstP1: props.p1Ctx,
            payload: {
              skierowanieOID: {
                root: props.poId.root,
                extension: props.poId.extension,
              },
            },
          },
        })
      : Promise.resolve(undefined)
  );

  const htmlIframeData = useMemo(() => {
    return data?.html ? `data:text/html;charset=utf-8,${encodeURIComponent(data?.html)}` : undefined;
  }, [data]);

  const header = props.poKluczu
    ? `Odczyt skierowania klucz: ${props.poKluczu.kluczSkierowania}, kod: ${props.poKluczu.kodSkierowania}`
    : `Odczyt skierowania root: ${props.poId?.root} ext: ${props.poId?.extension}`;

  const copyXmlToClipboard = () => {
    if (!data?.skierowanie?.xml) return;
    clipboardUtils.copyTextToClipboard(data.skierowanie.xml);
  };

  return (
    <DialogContent header={header}>
      <Grid gap>
        <GridItem rowTemplate="1fr">
          <LoadingRegion promise={promise}>
            <Grid gap>
              <GridItem colTemplate="1fr">
                {htmlIframeData ? (
                  <iframe src={htmlIframeData} title="print" style={{ width: "100%", height: "100%" }} />
                ) : (
                  "Brak danych wydruku HTML"
                )}
              </GridItem>
              <GridItem colTemplate="1fr">
                <pre>{data?.skierowanie?.xml || "Brak danych XML"}</pre>
              </GridItem>
            </Grid>
          </LoadingRegion>
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={copyXmlToClipboard} disabled={!data?.skierowanie?.xml}>
            Kopiuj XML do schowka
          </Button>
          <Button onClick={() => dialog.close()}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

export const skierowanieOdczytDialog = makeDialogOpener(SkierowanieOdczytDialog);
