import { faFileCode } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ClickableIcon,
  DialogContent,
  DialogProps,
  Form,
  formatDate,
  FormItem,
  Grid,
  GridItem,
  LoadingRegion,
  makeDialogOpener,
  Text,
  useDataLoader,
  useDialogSize,
} from "@optimed/react";
import { zdarzeniaMedyczneService } from "../../../api";
import {
  KontekstZapytaniaZm,
  rodzajIdentyfikatoraOsobyDescription,
  zmStatusZdarzeniaDescription,
  zmTypZdarzeniaDescription,
  ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu,
} from "../../../api/models";
import { encounterQueryP1RawResourceDialog } from "./EncounterQueryP1RawResourceDialog";

interface EncounterQueryP1DetailsDialogProps extends DialogProps<void> {
  zmContext: KontekstZapytaniaZm;
  idZasobu: string;
}

export function EncounterQueryP1DetailsDialog(props: EncounterQueryP1DetailsDialogProps) {
  useDialogSize("800px", "720px");
  const { dialog, zmContext, idZasobu } = props;
  const [data, promise] = useDataLoader(() =>
    zdarzeniaMedyczneService.zmPobierzDaneZdarzenia({
      kontekst: zmContext,
      payload: idZasobu,
    })
  );
  const header = `Encounter#${idZasobu}`;

  const openRawResource = (resource: ZmZdarzenieMedyczneDaneZp1SuroweDaneZasobu) => {
    encounterQueryP1RawResourceDialog.open({ resource });
  };

  return (
    <DialogContent header={header}>
      <Grid gap>
        <GridItem rowTemplate="1fr" scrollable>
          <LoadingRegion promise={promise}>
            {data && (
              <Form layout="vertical">
                <FormItem label="Błędy odczytu" hideIf={!data.bledyOdczytu?.length}>
                  {data.bledyOdczytu?.map((x, i) => (
                    <Text key={i} danger block>
                      {x}
                    </Text>
                  ))}
                </FormItem>

                <FormItem label="Źródłowe dane zasobów">
                  {data.zasoby?.map(x => (
                    <div key={`${x.typZasobu}#${x.idZasobu}`}>
                      <ClickableIcon icon={faFileCode} onClick={() => openRawResource(x)}>
                        {x.typZasobu}#{x.idZasobu}
                      </ClickableIcon>
                    </div>
                  ))}
                </FormItem>
                <FormItem label="Id epizodu">{data.idEpizodu}</FormItem>
                <FormItem label="Typ">{data.typ ? zmTypZdarzeniaDescription[data.typ] : "?"}</FormItem>
                <FormItem label="Status">{data.status ? zmStatusZdarzeniaDescription[data.status] : "?"}</FormItem>
                <FormItem label="Data">
                  {formatDate(data.dataOd, "yyyy-MM-dd HH:mm")}
                  {data.dataOd !== data.dataDo && <span> - {formatDate(data.dataDo, "yyyy-MM-dd HH:mm")}</span>}
                </FormItem>

                <FormItem label="Pacjent">
                  {data.pacjent?.imie} {data.pacjent?.nazwisko},&nbsp;
                  {data.pacjent?.rodzajIdentyfikatora
                    ? rodzajIdentyfikatoraOsobyDescription[data.pacjent.rodzajIdentyfikatora]
                    : "Nierozpoznany identyfikator"}
                  &nbsp;
                  {data.pacjent?.identyfikator}
                </FormItem>

                <FormItem label="Skierowanie">
                  <div hidden={Boolean(data.identyfikatorySkierowan?.length)}>Brak</div>
                  {data.identyfikatorySkierowan?.map((x, i) => (
                    <div key={i}>
                      {x.root}|{x.extension}
                    </div>
                  ))}
                </FormItem>

                <FormItem label="Kod resortowy I usługodawcy">{data.podmiotUslugodawca?.kodResortowyI || "-"}</FormItem>

                <FormItem label="Miejsce udzielenia świadczenia">
                  {data.miejscaUdzielaniaSwiadczen?.map((x, i) => (
                    <div key={i}>
                      {x.jednostka?.nazwa}, kod VII {x.jednostka?.kodResortowyVII}
                    </div>
                  ))}
                </FormItem>

                <FormItem label="Personel">
                  {data.personel?.map((x, i) => (
                    <div key={i}>
                      {x.imie} {x.nazwisko}
                      {x.npwz && `, npwz ${x.npwz}`}
                      {x.pesel && `, pesel ${x.pesel}`}
                    </div>
                  ))}
                </FormItem>

                <FormItem label="Procedury">
                  {data.procedury?.map((x, i) => (
                    <div key={i}>
                      <div>
                        {x.kod} {x.nazwa}
                      </div>
                      <small>
                        {formatDate(x.data, "yyyy-MM-dd HH:mm")}
                        {x.personelRealizujacy && (
                          <span>
                            , {x.personelRealizujacy.imie} {x.personelRealizujacy.nazwisko}
                          </span>
                        )}
                      </small>
                    </div>
                  ))}
                </FormItem>

                <FormItem label="Rozpoznania">
                  {data.rozpoznania?.map((x, i) => (
                    <div key={i}>
                      <div>
                        {x.kod} {x.nazwa}
                      </div>
                    </div>
                  ))}
                </FormItem>

                <FormItem label="Indeks dokumentów">
                  {!data.naglowkiDokumentow?.length && <div>Brak zaindeksowanych dokumentów</div>}
                  {data.naglowkiDokumentow?.map((x, i) => (
                    <div key={i}>
                      <div>
                        {formatDate(x.dataUtworzenia, "yyyy-MM-dd HH:mm")} {x.nazwa},{" "}
                        {x.statusDostepnosci || "nieokreślony status dostępności"}
                      </div>
                    </div>
                  ))}
                </FormItem>
              </Form>
            )}
          </LoadingRegion>
        </GridItem>

        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={dialog.cancel}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

export const encounterQueryP1DetailsDialog = makeDialogOpener(EncounterQueryP1DetailsDialog);
