import { Form, FormItem, TextField } from "@optimed/react";
import { ReceptyKontekstWywolaniaP1 } from "../../../api/models";

interface P1ContextFormProps {
  model: ReceptyKontekstWywolaniaP1;
  onChange: (value: ReceptyKontekstWywolaniaP1) => any;
}

export function P1ContextForm(props: P1ContextFormProps) {
  return (
    <Form model={props.model} onChange={props.onChange} layout="horizontal" labelWidth="200px">
      <FormItem label="Nr konta usługodawcy w P1">
        <TextField name="numerKontaUslugodawcy" />
      </FormItem>
      <FormItem label="Kod resortowy I">
        <TextField name="kodResortowyI" />
      </FormItem>
      <FormItem label="Kod resortowy V">
        <TextField name="kodResortowyV" />
      </FormItem>
      <FormItem label="Kod resortowy VII">
        <TextField name="kodResortowyVII" />
      </FormItem>
      <FormItem label="NPWZ osoby wystawiającej">
        <TextField name="npwz" />
      </FormItem>
    </Form>
  );
}
