import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { Icon, Panel, PanelBody, PanelHeader, PullRight } from "@optimed/react";
import { useState } from "react";
import { ReceptyKontekstWywolaniaP1 } from "../../../api/models";
import { P1ContextAutoInferer } from "./P1ContextAutoInferer";
import { P1ContextForm } from "./P1ContextForm";

interface P1ContextParamsProps {
  model: ReceptyKontekstWywolaniaP1;
  onChange: (value: ReceptyKontekstWywolaniaP1) => any;
  xml: string;
}

export function P1ContextParams(props: P1ContextParamsProps) {
  const [auto, setAuto] = useState(true);

  const body = auto ? (
    <P1ContextAutoInferer model={props.model} onChange={props.onChange} xml={props.xml} />
  ) : (
    <P1ContextForm model={props.model} onChange={props.onChange} />
  );

  return (
    <Panel>
      <PanelHeader>
        <PullRight>
          <Icon icon={faRetweet} onClick={() => setAuto(!auto)} />
        </PullRight>
        Kontekst P1
        {auto ? " - tryb automatyczny na podstawie XML" : " - tryb ręczny"}
      </PanelHeader>
      <PanelBody>{body}</PanelBody>
    </Panel>
  );
}
