import { faList, faPen } from "@fortawesome/free-solid-svg-icons";
import {
  ClickableIcon,
  Form,
  FormItem,
  FormNesting,
  SelectField,
  SelectInput,
  TextField,
  enumUtils,
  useDataLoader,
  useLocalStorage,
} from "@optimed/react";
import { environmentService } from "api";
import {
  P1CommunicationContextCacheEntryDto,
  ReceptyKontekstWywolaniaP1,
  TypCertyfikatu,
  TypIdentyfikatoraPersoneluMedycznego,
  TypSwiadczeniodawcy,
  ZawodMedyczny,
  ZmRolaUzytkownika,
} from "api/models";
import { useEffect, useMemo, useState } from "react";

interface ReceptaContextFormProps {
  value: ReceptyKontekstWywolaniaP1;
  onChange: (value: ReceptyKontekstWywolaniaP1) => any;
}

const userRoles = enumUtils.getNumericValues(ZawodMedyczny);

export function ReceptaContextForm(props: ReceptaContextFormProps) {
  const { value, onChange } = props;

  const [kodI, setKodI] = useLocalStorage("kontekstP1KodResortowyI", "");
  const [kodVII, setKodVII] = useLocalStorage("kontekstP1KodResortowyVII", "");
  const [nrKonta, setNrKonta] = useLocalStorage("kontekstP1NumerKontaUslugodawcyWP1", "");
  const [zawodMedyczny, setZawodMedyczny] = useLocalStorage("kontekstP1ZawodMedyczny", ZawodMedyczny.Lekarz);
  const [npwz, setNpwz] = useLocalStorage("kontekstP1Npwz", "");
  const [pesel, setPesel] = useLocalStorage("kontekstP1Pesel", "");
  const [tlsThumbprint, setTlsThumbprint] = useLocalStorage("kontekstP1TlsThumbprint", "");
  const [wssThumbprint, setWssThumbprint] = useLocalStorage("kontekstP1WssThumbprint", "");
  const [predefinedMode, setPredefinedMode] = useState(true);
  const [certs] = useDataLoader(() => environmentService.commonCertCache());
  const [cacheContexts] = useDataLoader(() =>
    environmentService.commonP1CommunicationContextCache().then(res => res.map(convertP1CommunicationContextCacheEntry))
  );
  const tlsCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1TlsPodmiot) || [], [certs]);
  const wssCerts = useMemo(() => certs?.filter(x => x.typ === TypCertyfikatu.P1WssPodmiot) || [], [certs]);

  useEffect(() => {
    onChange({
      ...value,
      kodResortowyI: kodI,
      kodResortowyVII: kodVII,
      zawodMedyczny,
      npwz,
      pesel,
      numerKontaUslugodawcy: nrKonta,
      certyfikatUwierzytelnieniaSystemu: {
        cacheThumbprint: tlsThumbprint,
      },
      certyfikatUwierzytelnieniaDanych: {
        cacheThumbprint: wssThumbprint,
      },
    });
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setKodI(value.kodResortowyI || "");
    setKodVII(value.kodResortowyVII || "");
    setNrKonta(value.numerKontaUslugodawcy || "");
    setZawodMedyczny(value.zawodMedyczny || ZawodMedyczny.Lekarz);
    setNpwz(value.npwz || "");
    setPesel(value.pesel || "");
    setTlsThumbprint(value.certyfikatUwierzytelnieniaSystemu?.cacheThumbprint || "");
    setWssThumbprint(value.certyfikatUwierzytelnieniaDanych?.cacheThumbprint || "");
    // eslint-disable-next-line @optimed/react-hooks/exhaustive-deps
  }, [value]);

  if (predefinedMode) {
    return (
      <Form layout="vertical" condensed>
        <FormItem label="Wybierz kontekst z cache">
          <SelectInput
            value={undefined}
            onChange={x => x && onChange(x)}
            items={cacheContexts || []}
            display={formatKontekstDisplay}
            filtering="multitokenIncludes"
          />
        </FormItem>
        <FormItem label="Aktualne dane kontekstu">
          <div>
            {value.kodResortowyI}
            {value.kodResortowyVII ? `-${value.kodResortowyVII}` : ""}, nr konta {value.numerKontaUslugodawcy}
          </div>
          <div>
            {value.zawodMedyczny ? ZawodMedyczny[value.zawodMedyczny] : value.zawodMedyczny}, npwz {value.npwz} pesel{" "}
            {value.pesel}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatUwierzytelnieniaSystemu?.cacheThumbprint ? `Wybrano certyfikat TLS` : "Nie wybrano TLS"}
          </div>
          <div style={{ wordBreak: "break-all" }}>
            {value.certyfikatUwierzytelnieniaDanych?.cacheThumbprint ? `Wybrano certyfikat WSS` : "Nie wybrano WSS"}
          </div>
          <div>
            <ClickableIcon icon={faPen} onClick={() => setPredefinedMode(false)}>
              Wprowadź ręcznie
            </ClickableIcon>
          </div>
        </FormItem>
      </Form>
    );
  } else {
    return (
      <Form layout="vertical" model={value} onChange={onChange} condensed>
        <FormItem label="Kod resortowy I">
          <TextField name="kodResortowyI" />
        </FormItem>
        <FormItem label="Kod resortowy VII (opcjonalnie)">
          <TextField name="kodResortowyVII" />
        </FormItem>
        <FormItem label="Nr konta usługodawcy w P1">
          <TextField name="numerKontaUslugodawcy" restrict={/^\d*$/} />
        </FormItem>
        <FormItem label="Zawód">
          <SelectField name="zawodMedyczny" items={userRoles} display={x => (x ? ZawodMedyczny[x] : "")} />
        </FormItem>
        <FormItem label="NPWZ">
          <TextField name="npwz" restrict={/^\d*$/} />
        </FormItem>
        <FormItem label="Pesel">
          <TextField name="pesel" restrict={/^\d*$/} />
        </FormItem>

        <FormNesting object="certyfikatUwierzytelnieniaSystemu" layout="vertical">
          <FormItem label="Certyfikat podmiotu TLS">
            <SelectField
              name="cacheThumbprint"
              items={tlsCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>

        <FormNesting object="certyfikatUwierzytelnieniaDanych" layout="vertical">
          <FormItem label="Certyfikat podmiotu WSS">
            <SelectField
              name="cacheThumbprint"
              items={wssCerts}
              itemKeyAsModel
              itemKey="thumbprint"
              display="friendlyName"
            />
          </FormItem>
        </FormNesting>
        <FormItem>
          <ClickableIcon icon={faList} onClick={() => setPredefinedMode(true)}>
            Wybierz z kontekstu
          </ClickableIcon>
        </FormItem>
      </Form>
    );
  }
}

function convertP1CommunicationContextCacheEntry(x: P1CommunicationContextCacheEntryDto): ReceptyKontekstWywolaniaP1 {
  return {
    typSwiadczeniodawcy: TypSwiadczeniodawcy.PodmiotLeczniczy,
    kodResortowyI: x.kodResortowyI,
    kodResortowyV: x.kodResortowyV,
    kodResortowyVII: x.kodResortowyVII,
    npwz:
      x.typIdentyfikatoraUzytkownika === TypIdentyfikatoraPersoneluMedycznego.Npwz
        ? x.identyfikatorUzytkownika
        : undefined,
    pesel:
      x.typIdentyfikatoraUzytkownika === TypIdentyfikatoraPersoneluMedycznego.Pesel
        ? x.identyfikatorUzytkownika
        : undefined,
    numerKontaUslugodawcy: x.numerKontaUslugodawcy.toString(),
    zawodMedyczny: convertRolaUzytkownikaToZawodMedyczny(x.rolaUzytkownika),
    certyfikatUwierzytelnieniaSystemu: {
      cacheThumbprint: x.certyfikatTlsThumbprint,
    },
    certyfikatUwierzytelnieniaDanych: {
      cacheThumbprint: x.certyfikatWssThumbprint,
    },
  };
}

function convertRolaUzytkownikaToZawodMedyczny(x: ZmRolaUzytkownika | undefined): ZawodMedyczny | undefined {
  switch (x) {
    case ZmRolaUzytkownika.Lekarz:
      return ZawodMedyczny.Lekarz;
    case ZmRolaUzytkownika.LekarzDentysta:
      return ZawodMedyczny.LekarzDentysta;
    case ZmRolaUzytkownika.Felczer:
      return ZawodMedyczny.Felczer;
    case ZmRolaUzytkownika.Pielegniarka:
      return ZawodMedyczny.Pielegniarka;
    case ZmRolaUzytkownika.Polozna:
      return ZawodMedyczny.Polozna;
    case ZmRolaUzytkownika.Farmaceuta:
      return ZawodMedyczny.Farmaceuta;
    case ZmRolaUzytkownika.RatownikMedyczny:
      return ZawodMedyczny.RatownikMedyczny;
    case ZmRolaUzytkownika.ProfesjonalistaMedyczny:
      return undefined;
    case ZmRolaUzytkownika.PracownikAdministracyjny:
      return undefined;
    case ZmRolaUzytkownika.DiagnostaLaboratoryjny:
      return ZawodMedyczny.DiagnostaLaboratoryjny;
    case ZmRolaUzytkownika.Fizjoterapetua:
      return ZawodMedyczny.Fizjoterapeuta;
    default:
      return undefined;
  }
}

function formatKontekstDisplay(x: ReceptyKontekstWywolaniaP1): string {
  const rola = x.zawodMedyczny ? ZawodMedyczny[x.zawodMedyczny] : `Zawód medyczny ${x.zawodMedyczny}`;
  return `${rola}, npwz ${x.npwz} pesel ${x.pesel}, kod res I ${x.kodResortowyI}, nr konta ${x.numerKontaUslugodawcy}`;
}
