import {
  Button,
  DialogContent,
  DialogProps,
  Form,
  formatDate,
  FormItem,
  Grid,
  GridItem,
  LoadingRegion,
  Panel,
  PanelBody,
  PanelTitle,
  Table,
  useDataLoader,
  useDialogSize,
} from "@optimed/react";
import React from "react";
import { receptyService } from "../../../api";
import { KomunikatWalidacji, ReceptyRecepta, TypKomunikatuWalidacji } from "../../../api/models";

interface PrescriptionValidationMessagesDialogProps extends DialogProps<void> {
  idRecepty: number;
}

export function PrescriptionValidationMessagesDialog(props: PrescriptionValidationMessagesDialogProps) {
  const { dialog, idRecepty } = props;
  useDialogSize("1000px", "700px");
  const [data, promise] = useDataLoader(() => receptyService.daneWystawionejRecepty({ idRecepty }));
  const dialogTitle = `Szczegóły recepty id=${idRecepty}`;

  return (
    <DialogContent header={dialogTitle}>
      <Grid gap>
        <GridItem rowTemplate="1fr" scrollable>
          <LoadingRegion promise={promise}>{(data && <ReceptaView recepta={data} />) || null}</LoadingRegion>
        </GridItem>
        <GridItem rowTemplate="auto" contentAlign="right">
          <Button onClick={() => dialog.close()}>Zamknij</Button>
        </GridItem>
      </Grid>
    </DialogContent>
  );
}

function getTypKomunikatuLabel(typ?: TypKomunikatuWalidacji) {
  return typ === TypKomunikatuWalidacji.Blad
    ? "Błąd"
    : typ === TypKomunikatuWalidacji.Ostrzezenie
    ? "Ostrzeżenie"
    : typ;
}

function TabelaKomunikatow(props: { komunikaty: KomunikatWalidacji[] }) {
  const { komunikaty } = props;
  const rows = komunikaty.length ? (
    komunikaty.map((x, i) => (
      <tr key={i}>
        <td>{getTypKomunikatuLabel(x.typ)}</td>
        <td>{x.kod}</td>
        <td>
          {x.komunikat}
          <div>
            <small>Szczegóły: {x.szczegoly}</small>
          </div>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={4}>Brak komunikatów</td>
    </tr>
  );

  return (
    <Table>
      <thead>
        <tr>
          <th>Rodzaj</th>
          <th>Kod</th>
          <th>Komuinkat</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}

function ReceptaView(props: { recepta: ReceptyRecepta }) {
  const { recepta: r } = props;

  const messages = (r.wynikWalidacji && r.wynikWalidacji.komunikaty) || [];

  return (
    <Panel style={{ height: "100%", marginBottom: "0px" }}>
      <PanelBody>
        <PanelTitle>
          Recepta id={r.id}
          {r.anulowana && ", anulowana"}
        </PanelTitle>
        <Form layout="vertical">
          <FormItem label="Data dodania">{formatDate(r.dataDodania, "yyyy-MM-dd HH:mm:ss")}</FormItem>
          <FormItem label="Numer recepty w pakiecie">{r.numerReceptyWPakiecie}</FormItem>
          <FormItem label="Klucz recepty">{r.kluczRecepty}</FormItem>
          <FormItem label="Identyfikator dokumentu">{r.identyfikatorDokumentu}</FormItem>
          <FormItem label="Ostrzeżenia/błędy">
            <TabelaKomunikatow komunikaty={messages} />
          </FormItem>
        </Form>
      </PanelBody>
    </Panel>
  );
}
