import React from "react";

interface StringifyViewerProps {
  value: any;
}

export class StringifyViewer extends React.PureComponent<StringifyViewerProps> {
  render() {
    return <pre style={{ whiteSpace: "pre-wrap" }}>{JSON.stringify(this.props.value, undefined, 2)}</pre>;
  }
}
