import { RozszerzoneWyszukiwanieReceptPage } from "domain/recepty/rozszerzoneWyszukiwanieReceptUslugobiorcy/RozszerzoneWyszukiwanieReceptPage";
import { ZgodyPacjentaOdczytPage } from "domain/zgodyPacjenta";
import { Navigate, Route, Routes } from "react-router-dom";
import { FindPrescriptionsPage } from "../recepty/findPrescriptions/FindPrescriptionsPage";
import { ManualXmlSendPage } from "../recepty/manualXml/ManualXmlSendPage";
import { ServiceOperationsPage } from "../serviceOperations/ServiceOperationsPage";
import { SkierowanieOdczytPage } from "../skierowania";
import { EncounterQueryP1Page } from "../zdarzeniaMedyczne/queryP1/EncounterQueryP1Page";
import { RawFhirGetPage } from "../zdarzeniaMedyczne/rawFhirGet/RawFhirGetPage";
import "./MainRouterSwitch.scss";
import { routes } from "./routes";

export function MainRouterSwitch() {
  return (
    <div className="main-switch-container">
      <Routes>
        <Route path={routes.prescriptions.find} element={<FindPrescriptionsPage />} />
        <Route path={routes.prescriptions.manualXml} element={<ManualXmlSendPage />} />
        <Route path={routes.prescriptions.findInP1} element={<RozszerzoneWyszukiwanieReceptPage />} />
        <Route path={routes.serviceOperations} element={<ServiceOperationsPage />} />
        <Route path={routes.encounters.queryP1} element={<EncounterQueryP1Page />} />
        <Route path={routes.encounters.rawFhirGet} element={<RawFhirGetPage />} />
        <Route path={routes.referrals.read} element={<SkierowanieOdczytPage />} />
        <Route path={routes.patientConsents.read} element={<ZgodyPacjentaOdczytPage />} />
        <Route path="*" element={<Navigate to={routes.prescriptions.find} />} />
      </Routes>
    </div>
  );
}
