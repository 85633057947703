import { BaseContainer, Button, debounce, dialogManager, Grid, GridItem, LoadingRegion } from "@optimed/react";
import React from "react";
import { receptyTestService } from "../../../api";
import { ReceptyKontekstWywolaniaP1, ReceptyWeryfikacjaPakietuReceptResult } from "../../../api/models";
import { XmlInput } from "../../../core/XmlInput";
import { PrescriptionPacketPrintDialog } from "../findPrescriptions/PrescriptionPacketPrintDialog";
import { PrescriptionPrintDialog } from "../findPrescriptions/PrescriptionPrintDialog";
import { P1ContextParams } from "./P1ContextParams";
import { StringifyViewer } from "./StringifyViewer";
import { WeryfikacjaReceptResultViewer } from "./WeryfikacjaReceptResultViewer";

interface ManualXmlSendPageProps {}

interface ManualXmlSendPageState {
  p1Context: ReceptyKontekstWywolaniaP1;
  xml: string;
  requestPromise?: Promise<any>;
  weryfikacjaResult?: ReceptyWeryfikacjaPakietuReceptResult;
  error?: any;
}

const localStorageKey = "manualxmldata";

interface LocalStorageValue {
  p1Context: ReceptyKontekstWywolaniaP1;
  xml: string;
}

export class ManualXmlSendPage extends React.Component<ManualXmlSendPageProps, ManualXmlSendPageState> {
  private saveToLocalStorageDebounced: () => any;

  constructor(props: ManualXmlSendPageProps) {
    super(props);

    this.handleXmlChange = this.handleXmlChange.bind(this);
    this.handleP1ContextChange = this.handleP1ContextChange.bind(this);
    this.validate = this.validate.bind(this);
    this.saveToLocalStorage = this.saveToLocalStorage.bind(this);
    this.printPacket = this.printPacket.bind(this);
    this.printPrescription = this.printPrescription.bind(this);
    this.saveToLocalStorageDebounced = debounce(this.saveToLocalStorage, 250);
    this.randomizeXmlDocumentId = this.randomizeXmlDocumentId.bind(this);

    this.state = {
      p1Context: {
        kodResortowyI: "",
        kodResortowyV: "",
        kodResortowyVII: "",
        numerKontaUslugodawcy: "",
      },
      xml: "",
    };
  }

  componentDidMount() {
    const item = localStorage.getItem(localStorageKey);
    if (item) {
      const obj = JSON.parse(item) as LocalStorageValue;
      this.setState({
        p1Context: obj.p1Context || {},
        xml: obj.xml,
      });
    }
  }

  componentWillUnmount() {
    this.saveToLocalStorage();
  }

  render() {
    return (
      <Grid gap>
        <GridItem colTemplate="1fr">{this.renderInput()}</GridItem>
        <GridItem colTemplate="1fr">{this.renderResult()}</GridItem>
      </Grid>
    );
  }

  renderInput() {
    return (
      <Grid gap>
        <GridItem rowTemplate="auto">
          <P1ContextParams model={this.state.p1Context} onChange={this.handleP1ContextChange} xml={this.state.xml} />
        </GridItem>
        <GridItem rowTemplate="1fr">
          <XmlInput value={this.state.xml} onChange={this.handleXmlChange} />
        </GridItem>
        <GridItem rowTemplate="auto">
          <Button onClick={this.validate}>Wykonaj walidację</Button>
          <Button onClick={this.printPacket}>Wydruk pakietu</Button>
          <Button onClick={this.printPrescription}>Wydruk recepty</Button>
          <Button onClick={this.randomizeXmlDocumentId}>Losuj id dokumentu w XML</Button>
        </GridItem>
      </Grid>
    );
  }

  renderResult() {
    return (
      <BaseContainer padding scrollable>
        {this.renderResultViewer()}
      </BaseContainer>
    );
  }

  renderResultViewer() {
    const { weryfikacjaResult, error } = this.state;
    if (weryfikacjaResult) {
      return <WeryfikacjaReceptResultViewer result={weryfikacjaResult} />;
    }

    if (error) {
      return (
        <Grid>
          <GridItem rowTemplate="auto" padding={false}>
            Error
          </GridItem>
          <GridItem rowTemplate="1fr" scrollable padding={false}>
            <StringifyViewer value={error} />
          </GridItem>
        </Grid>
      );
    }

    return <LoadingRegion promise={this.state.requestPromise}>&nbsp;</LoadingRegion>;
  }

  handleP1ContextChange(p1Context: ReceptyKontekstWywolaniaP1 | undefined) {
    this.setState({
      p1Context: p1Context || {},
    });
    this.saveToLocalStorageDebounced();
  }

  handleXmlChange(xml: string) {
    this.setState({
      xml,
    });
    this.saveToLocalStorageDebounced();
  }

  validate() {
    const { xml, requestPromise } = this.state;
    if (!xml || requestPromise) return;

    const promise = receptyTestService
      .weryfikacjaPakietuReceptZXml({
        params: {},
        data: {
          kontekstP1: this.state.p1Context,

          payload: {
            recepty: [
              {
                xml: this.state.xml,
              },
            ],
          },
        },
      })
      .then(res => {
        this.setState({
          weryfikacjaResult: res,
        });
      })
      .catch(err => {
        const res = (err && err.response && err.response.data) || err;

        this.setState({
          error: res,
        });
      })
      .finally(() => this.setState({ requestPromise: undefined }));

    this.setState({
      requestPromise: promise,
      weryfikacjaResult: undefined,
      error: undefined,
    });
  }

  printPacket() {
    const { xml } = this.state;
    if (!xml) return;

    dialogManager.open(PrescriptionPacketPrintDialog, {
      xml,
    });
  }

  printPrescription() {
    const { xml } = this.state;
    if (!xml) return;

    dialogManager.open(PrescriptionPrintDialog, {
      xml,
    });
  }

  randomizeXmlDocumentId() {
    const { xml } = this.state;
    if (!xml) return;

    const idMatch = /root="2\.16\.840\.1\.113883\.3\.4424\.2\.7\.\d+\.2\.1" extension="([^"]+)"/.exec(xml);
    if (!idMatch) return;
    const id = idMatch[1];
    const randomizeEnd = Math.floor(Math.random() * 1000 + 1000);
    const newId = id.substring(0, id.length - 4) + randomizeEnd;

    const newXml = xml.replace(new RegExp(id, "g"), newId);
    this.setState({
      xml: newXml,
    });
  }

  saveToLocalStorage() {
    const value: LocalStorageValue = {
      p1Context: this.state.p1Context,
      xml: this.state.xml,
    };
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }
}
