import {
  DateField,
  Form,
  FormItem,
  NumberField,
  SelectField,
  SubmitButton,
  TextField,
  TimeField,
} from "@optimed/react";
import React from "react";
import {
  ReceptyDaneWyszukiwaniaLokalnychRecept,
  ReceptyStatusPakietuRecept,
  receptyStatusPakietuReceptDescription,
} from "../../../api/models";

interface FindPrescriptionsCriteriaProps {
  criteria: ReceptyDaneWyszukiwaniaLokalnychRecept;
  onChange: (criteria: ReceptyDaneWyszukiwaniaLokalnychRecept) => any;
  onSubmit: (criteria: ReceptyDaneWyszukiwaniaLokalnychRecept) => any;
}

const statusyPakietu = [
  ReceptyStatusPakietuRecept.PomyslnieWyslano,
  ReceptyStatusPakietuRecept.BladWysylania,
  ReceptyStatusPakietuRecept.PrzetwarzanieP1,
  ReceptyStatusPakietuRecept.PrzygotowanyDoWysylki,
];

export const FindPrescriptionsCriteria = React.memo(function FindPrescriptionsCriteria(
  props: FindPrescriptionsCriteriaProps
) {
  const { criteria, onChange, onSubmit } = props;
  return (
    <Form layout="vertical" condensed model={criteria} onChange={onChange} onSubmit={onSubmit}>
      <FormItem label="Data od">
        <DateField name="dataOd" modelFormat="ISO8601" />
        <TimeField name="dataOd" modelFormat="ISO8601" />
      </FormItem>

      <FormItem label="Data do">
        <DateField name="dataDo" modelFormat="ISO8601" />
        <TimeField name="dataDo" modelFormat="ISO8601" />
      </FormItem>

      <FormItem label="Status pakietu">
        <SelectField
          name="statusPakietu"
          items={statusyPakietu}
          display={x => receptyStatusPakietuReceptDescription[x]}
        />
      </FormItem>

      <FormItem label="Kod dostępu pakietu (4 znaki)">
        <TextField name="kodDostepu" />
      </FormItem>

      <FormItem label="Kod pakietu">
        <TextField name="kodPakietu" />
      </FormItem>

      <FormItem label="Klucz pakietu">
        <TextField name="kluczPakietu" />
      </FormItem>

      <FormItem label="Id zewnętrzne pakietu">
        <NumberField name="idZewnetrznePakietu" />
      </FormItem>

      <FormItem label="Id zewnętrzne recepty">
        <NumberField name="idZewnetrzneRecepty" />
      </FormItem>

      <FormItem label="System zewnętrzny">
        <TextField name="systemZewnetrzny" />
      </FormItem>

      <SubmitButton>Szukaj</SubmitButton>
    </Form>
  );
});
