import React from "react";
import { appVersion } from "../../core/AppState";
import "./AppVersionInfo.scss";
import { ServerInfo } from "./ServerInfo";

export function AppVersionInfo() {
  return (
    <span className="app-version-info">
      <span>
        Client: {appVersion.app} build {appVersion.build}
      </span>
      <ServerInfo />
    </span>
  );
}
